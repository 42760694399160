import React from "react";
import { createRoot } from "react-dom/client";

import ContentManagementAppV1 from "./ContentManagement/ContentManagementAppV1";

const container = document.getElementById("root");
const root = createRoot(container as any);
root.render(
    <React.StrictMode>
        <ContentManagementAppV1/>
    </React.StrictMode>
);
