import { buildCollection } from "firecms";
import { collectionVersionedPrefix } from "../data_version";
import { NomadCloudVoucher } from "../types";

export const vouchersCollection = buildCollection<NomadCloudVoucher>({
    path: `${collectionVersionedPrefix()}/vouchers`,
    name: "Vouchers",
    singularName: "Voucher",
    group: "Operations",
    description: "Vouchers",
    textSearchEnabled: false,
    icon: "LocalActivity",
    properties: {
        redeemed: {
            name: "Redeemed",
            dataType: "date",
            readOnly: true
        },
        redeemedBy: {
            name: "Redeemed By",
            dataType: "reference",
            description: "User",
            path: `${collectionVersionedPrefix()}/users`,
            previewProperties: ["displayName"],
            readOnly: true
        },
        user: {
            dataType: "reference",
            name: "User",
            description: "User",
            path: `${collectionVersionedPrefix()}/users`,
            previewProperties: ["displayName"],
            readOnly: true
        },
        booking: {
            dataType: "reference",
            name: "Booking",
            description: "Booking",
            path: `${collectionVersionedPrefix()}/bookings`,
            previewProperties: ["tripTitle"],
            readOnly: true
        },
        voucherCode: {
            name: "Voucher Code",
            dataType: "string",
            readOnly: true
        },
        conditions: {
            name: "Conditions",
            dataType: "string",
            readOnly: true
        },
        voucherType: {
            dataType: "reference",
            name: "Voucher Type",
            description: "Voucher Type",
            path: `${collectionVersionedPrefix()}/voucherTypes`,
            previewProperties: ["title"],
            readOnly: true
        },
        modifiedAt: {
            name: "Modified At",
            dataType: "date",
            autoValue: "on_update",
            readOnly: true,
        },
        createdAt: {
            name: "Created At",
            dataType: "date",
            autoValue: "on_create",
            readOnly: true,
        },
    },
});
