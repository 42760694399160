import { EntityReference, PropertiesOrBuilders, buildCollection, buildEntityCallbacks } from "firecms";
import { getLocales } from "../../locale_config";
import { collectionVersionedPrefix } from "../data_version";
import { NomadCloudAccommodationFeature, NomadCloudAccommodationFeatureLocale } from "../types";

const accommodationFeatureProperties = {
    active: {
        name: "Active",
        description: "Is the Accommodation Feature Active?",
        dataType: "boolean",
    },
    name: {
        name: "Name",
        dataType: "string",
        description: "Accommodation Feature name. (Max 20 chars)",
        validation: { required: true, max: 20 },
    },
    modifiedAt: {
        name: "Modified At",
        dataType: "date",
        autoValue: "on_update",
        readOnly: true,
    },
    modifiedBy: {
        dataType: "reference",
        name: "Modified By",
        path: `${collectionVersionedPrefix()}/users`,
        previewProperties: ["displayName"],
        readOnly: true,
    },
    createdAt: {
        name: "Created At",
        dataType: "date",
        autoValue: "on_create",
        readOnly: true,
    },
    createdBy: {
        dataType: "reference",
        name: "Created By",
        path: `${collectionVersionedPrefix()}/users`,
        previewProperties: ["displayName"],
        readOnly: true,
    },
} as PropertiesOrBuilders<NomadCloudAccommodationFeature>;

export const accommodationFeatureCollection = () => {
    return buildCollection<NomadCloudAccommodationFeature>({
        path: `${collectionVersionedPrefix()}/accommodationFeatures`,
        name: "Accommodation Features",
        singularName: "Accommodation Feature",
        group: "Lists",
        description: "Accommodation Features",
        textSearchEnabled: false,
        icon: "Bathtub",
        properties: accommodationFeatureProperties,
        subcollections: [accommodationFeatureLocaleCollection],
        callbacks: buildEntityCallbacks({
            onPreSave: async ({ values, entityId, context }) => {
                if (!entityId) {
                    // creation
                    values.createdBy = new EntityReference(
                        context.authController.user!.uid,
                        `${collectionVersionedPrefix()}/users`
                    );
                }

                values.modifiedBy = new EntityReference(
                    context.authController.user!.uid,
                    `${collectionVersionedPrefix()}/users`
                );
                return values;
            },
        }),
    });
}

export const accommodationFeatureLocaleCollection = buildCollection<NomadCloudAccommodationFeatureLocale>({
    path: "translation",
    customId: getLocales(),
    name: "Translation",
    singularName: "Translation",
    properties: {
        name: accommodationFeatureProperties.name,
        
        modifiedAt: accommodationFeatureProperties.modifiedAt,
        modifiedBy: accommodationFeatureProperties.modifiedBy,
        createdAt: accommodationFeatureProperties.createdAt,
        createdBy: accommodationFeatureProperties.createdBy,
    }
});
