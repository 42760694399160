/* eslint-disable import/no-anonymous-default-export */
  export const firebaseConfig = {
    apiKey: "AIzaSyC13bT4xzbiDQW5UTAOh7ynKupOErGFzs8",
    authDomain: "nc-tenant-dev.firebaseapp.com",
    databaseURL: "https://nc-tenant-dev-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "nc-tenant-dev",
    storageBucket: "nc-tenant-dev.appspot.com",
    messagingSenderId: "998085666458",
    appId: "1:998085666458:web:44ddd1a6fce48ecbc96123",
    measurementId: "G-SXFTHWD399",
  };