import { EntityReference, PropertiesOrBuilders, buildCollection, buildEntityCallbacks } from "firecms";
import { getCountries } from "../../country_config";
import { getLocales } from "../../locale_config";
import { collectionVersionedPrefix } from "../data_version";
import { NomadCloudCountry, NomadCloudCountryLocale } from "../types";

const countryProperties = {
    active: {
        name: "Active",
        description: "Is the Country Active?",
        dataType: "boolean",
    },
    featured: {
        name: "Featured ⭐",
        description: "Is the Country Featured / Shown on the landing page?",
        dataType: "boolean",
    },
    name: {
        name: "Name",
        description: "Name of the Country, e.g. Solmon Islands",
        dataType: "string",
        enumValues: getCountries(),
    },
    description: {
        name: "Description",
        description: "Description of the Country used in the App",
        dataType: "string",
        multiline: true,
        markdown: true,
    },
    infoMarkdown: {
        name: "Info (markdown)",
        description: "Info (markdown) (Max 350 chars)",
        validation: { required: true, max: 350 },
        dataType: "string",
        multiline: true,
        markdown: true,
    },
    coverImageFirebaseUrl: {
        name: "Cover Image",
        description: "Cover Image",
        dataType: "string",
        storage: {
            storagePath: "images/countries",
            acceptedFiles: ["image/*"],
            metadata: {
                cacheControl: "max-age=1000000",
            },
        },
    },
    coverImageFirebaseSignedUrl: {
        name: "Cover Image (signed)",
        dataType: "string",
        readOnly: true,
        disabled: {
            hidden: true,
        },
    },
    destinationCount: {
        name: "Destination Count",
        description: "Number of Destinations in this Country (auto-calculated)",
        dataType: "number",
        readOnly: true,
    },
    modifiedAt: {
        name: "Modified At",
        dataType: "date",
        autoValue: "on_update",
        readOnly: true,
    },
    modifiedBy: {
        dataType: "reference",
        name: "Modified By",
        path: `${collectionVersionedPrefix()}/users`,
        previewProperties: ["displayName"],
        readOnly: true,
    },
    createdAt: {
        name: "Created At",
        dataType: "date",
        autoValue: "on_create",
        readOnly: true,
    },
    createdBy: {
        dataType: "reference",
        name: "Created By",
        path: `${collectionVersionedPrefix()}/users`,
        previewProperties: ["displayName"],
        readOnly: true,
    },
} as PropertiesOrBuilders<NomadCloudCountry>;

export const countryCollection = () => {
    return buildCollection<NomadCloudCountry>({
        path: `${collectionVersionedPrefix()}/countries`,
        name: "Countries",
        singularName: "Country",
        group: "Main",
        description: "Countries",
        textSearchEnabled: false,
        icon: "Flag",
        subcollections: [countryLocaleCollection],
        properties: countryProperties,
        customId: getCountries(),
        callbacks: buildEntityCallbacks({
            onPreSave: async ({ values, entityId, context }) => {
                if (!entityId) {
                    // creation
                    values.createdBy = new EntityReference(
                        context.authController.user!.uid,
                        `${collectionVersionedPrefix()}/users`
                    );
                }

                values.modifiedBy = new EntityReference(
                    context.authController.user!.uid,
                    `${collectionVersionedPrefix()}/users`
                );
                return values;
            },
        }),
    });
}

export const countryLocaleCollection = buildCollection<NomadCloudCountryLocale>({
    path: "translation",
    customId: getLocales(),
    name: "Translation",
    singularName: "Translation",
    properties: {
        // name: {
        //     name: "Name",
        //     description: "Name of the Country, e.g. Solmon Islands",
        //     dataType: "string",
        // },
        description: countryProperties.description,
        infoMarkdown: countryProperties.infoMarkdown,
        
        modifiedAt: countryProperties.modifiedAt,
        modifiedBy: countryProperties.modifiedBy,
        createdAt: countryProperties.createdAt,
        createdBy: countryProperties.createdBy,
    }
});