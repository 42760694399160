import { continents as libContinents, countries as libCountries } from "countries-list";
import { NomadCloudCountryRecord } from "./types";

// countries

const countryComparison = (a: NomadCloudCountryRecord, b: NomadCloudCountryRecord) => {
    if (a.name < b.name) {
        return -1;
    }
    if (a.name > b.name) {
        return 1;
    }

    return 0;
};

type countryKey = keyof typeof libCountries;
type continentKey = keyof typeof libContinents;
export const countries = (Object.keys(libCountries) as countryKey[])
    .map((k: countryKey) => {
        return {
            code: k,
            name: libCountries[k].name,
            continentCode: libCountries[k].continent as continentKey,
        };
    })
    .sort(countryComparison)
    .map((c) => {
        return {
            code: c.code,
            name: `${c.name} (${libContinents[c.continentCode]})`,
        };
    })
    .map((country) => {
        return {
            id: country.code,
            label: country.name,
        };
    });
