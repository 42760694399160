import { NomadCloudProduct, NomadCloudProductLocale } from "../types";
import { EntityReference, PropertiesOrBuilders, buildCollection, buildEntityCallbacks } from "firecms";
import { collectionVersionedPrefix } from "../data_version";
import { getLocales } from "../../locale_config";
import { itineraryCollection } from "./itinerary_collection";

const productProperties = {
    active: {
        name: "Active",
        dataType: "boolean",
        description: "Is the Product Active?",
    },
    featured: {
        name: "Featured ⭐",
        description: "Is the Product Featured / Shown on the landing page?",
        dataType: "boolean",
    },
    title: {
        name: "Title",
        description: "Product Title (Max 80 chars)",
        validation: {
            required: true,
            max: 120,
        },
        dataType: "string",
    },
    description: {
        name: "Description",
        dataType: "string",
        description: "Product Description (Max 1000 chars)",
        validation: {
            required: true,
            max: 1000,
        },
        multiline: true,
        markdown: true,
        columnWidth: 300,
    },
    productImageFirebaseUrl: {
        name: "Product Image",
        description: "Product shown in the App",
        validation: {
            required: true,
        },
        dataType: "string",
        storage: {
            storagePath: "images/products",
            acceptedFiles: ["image/*"],
        },
    },
    productImageFirebaseSignedUrl: {
        name: "Product Image Url",
        dataType: "string",
        readOnly: true,
        disabled: {
            hidden: true,
        },
    },
    price: {
        description: "Regular pricing for 1 Adult. Is displayed on home screen.",
        name: "Price",
        dataType: "number",
    },
    deal: {
        description: "Deal name, such as '50% OFF' or 'SUMMER DEALS'. Is displayed on home screen.",
        name: "Deal",
        dataType: "string",
    },
    dealColor: {
        description: "Please use HTML Color. Is displayed on home screen.",
        name: "Deal Color",
        dataType: "string",
    },
    modifiedAt: {
        name: "Modified At",
        dataType: "date",
        autoValue: "on_update",
        readOnly: true,
    },
    modifiedBy: {
        dataType: "reference",
        name: "Modified By",
        path: `${collectionVersionedPrefix()}/users`,
        previewProperties: ["displayName"],
        readOnly: true,
    },
    createdAt: {
        name: "Created At",
        dataType: "date",
        autoValue: "on_create",
        readOnly: true,
    },
    createdBy: {
        dataType: "reference",
        name: "Created By",
        path: `${collectionVersionedPrefix()}/users`,
        previewProperties: ["displayName"],
        readOnly: true,
    },
} as PropertiesOrBuilders<NomadCloudProduct>;

export const productCollection = () => {
    return buildCollection<NomadCloudProduct>({
        path: `${collectionVersionedPrefix()}/products`,
        name: "Products",
        singularName: "Product",
        group: "Ecommerce",
        description: "Products",
        textSearchEnabled: false,
        icon: "Storefront",
        subcollections: [productLocaleCollection, itineraryCollection()],
        properties: productProperties,
        callbacks: buildEntityCallbacks({
            onPreSave: async ({ values, entityId, context }) => {
                if (!entityId) {
                    // creation
                    values.createdBy = new EntityReference(
                        context.authController.user!.uid,
                        `${collectionVersionedPrefix()}/users`
                    );
                }

                values.modifiedBy = new EntityReference(
                    context.authController.user!.uid,
                    `${collectionVersionedPrefix()}/users`
                );
                return values;
            },
        }),
    });
};

export const productLocaleCollection = buildCollection<NomadCloudProductLocale>({
    path: "translation",
    customId: getLocales(),
    name: "Translation",
    singularName: "Translation",
    properties: {
        title: productProperties.title,
        description: productProperties.description,
        deal: productProperties.deal,

        modifiedAt: productProperties.modifiedAt,
        modifiedBy: productProperties.modifiedBy,
        createdAt: productProperties.createdAt,
        createdBy: productProperties.createdBy,
    },
});
