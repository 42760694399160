import { PropertiesOrBuilders, buildCollection } from "firecms";
import { getLocales } from "../../locale_config";
import CustomColorField from "../custom_fields_v2/CustomColorField";
import { collectionVersionedPrefix } from "../data_version";
import { NomadCloudAppColorSettings, NomadCloudAppColorSettingsLocale } from "./color.settings.types";

export const appColorSettingsProperties = {
    primaryColor: {
        name: "Primary Color",
        description: "Primary Color",
        dataType: "string",
        Field: CustomColorField,
    },
    secondaryColor: {
        name: "Secondary Color",
        description: "Secondary Color",
        dataType: "string",
        Field: CustomColorField,
    },
    navbarActiveButtonColor: {
        name: "Navbar Active Button Color",
        description: "Navbar Active Butto Color",
        dataType: "string",
        Field: CustomColorField,
    },
    navbarInactiveButtonColor: {
        name: "Navbar Inactive Button Color",
        description: "Navbar Inactive Button Color",
        dataType: "string",
        Field: CustomColorField,
    },
    backgroundColor: {
        name: "Background Color",
        description: "Background Color",
        dataType: "string",
        Field: CustomColorField,
    },
    primaryFontColor: {
        name: "Primary Font Color",
        description: "Primary Font Color",
        dataType: "string",
        Field: CustomColorField,
    },
    secondaryFontColor: {
        name: "Secondary Font Color",
        description: "Secondary Font Color",
        dataType: "string",
        Field: CustomColorField,
    },
    primaryButtonColor: {
        name: "Primary Button Color",
        description: "Primary Button Color",
        dataType: "string",
        Field: CustomColorField,
    },
    primaryButtonFontColor: {
        name: "Primary Button Font Color",
        description: "Primary Button Font Color",
        dataType: "string",
        Field: CustomColorField,
    },
    secondaryButtonColor: {
        name: "Secondary Button Color",
        description: "Secondary Button Color",
        dataType: "string",
        Field: CustomColorField,
    },
    secondaryButtonFontColor: {
        name: "Secondary Button Font Color",
        description: "Secondary Button Font Color",
        dataType: "string",
        Field: CustomColorField,
    },
    middleButtonColor: {
        name: "Middle Button Color",
        description: "Middle Button Color",
        dataType: "string",
        Field: CustomColorField,
    },
    textFieldColor: {
        name: "TextField Color",
        description: "TextField Color",
        dataType: "string",
        Field: CustomColorField,
    },
    textFieldFontColor: {
        name: "TextField Font olor",
        description: "TextField Font Color",
        dataType: "string",
        Field: CustomColorField,
    },
    textFieldHintFontColor: {
        name: "TextField Hint Font Color",
        description: "TextField Hint Font Color",
        dataType: "string",
        Field: CustomColorField,
    },

    quoteTitleFontColor: {
        name: "Quote Title Font Color",
        description: "Quote Title Font Color",
        dataType: "string",
        Field: CustomColorField,
    },
    quoteTextFontColor: {
        name: "Quote Text Font Color",
        description: "Quote Text Font Color",
        dataType: "string",
        Field: CustomColorField,
    },
    quoteBackgroundColor: {
        name: "Quote Background Color",
        description: "Quote Background Color",
        dataType: "string",
        Field: CustomColorField,
    },
    tileTitleFontColor: {
        name: "Tile Title Font Color",
        description: "Tile Title Font Color",
        dataType: "string",
        Field: CustomColorField,
    },
    tileSubtitleFontColor: {
        name: "Tile Subtitle Font Color",
        description: "Tile Subtitle Font Color",
        dataType: "string",
        Field: CustomColorField,
    },
    heartActiveColor: {
        name: "Heart Active Color",
        description: "Heart Active Color",
        dataType: "string",
        Field: CustomColorField,
    },
    heartInactiveColor: {
        name: "Heart Inactive Color",
        description: "Heart Inactive Color",
        dataType: "string",
        Field: CustomColorField,
    },
    pinColor: {
        name: "Pin Color (map)",
        description: "Pin Color (map)",
        dataType: "string",
        Field: CustomColorField,
    },
} as PropertiesOrBuilders<NomadCloudAppColorSettings>;

const appColorSettingsLocaleCollection = buildCollection<NomadCloudAppColorSettingsLocale>({
    path: "translation",
    customId: getLocales(),
    name: "Translation",
    singularName: "Translation",
    properties: {
        primaryColor: appColorSettingsProperties.primaryColor,
    },
});

export const appColorSettingsCollection = (name: string) =>
    buildCollection({
        path: `${collectionVersionedPrefix()}/operatorSettings`,
        name,
        properties: appColorSettingsProperties,
        subcollections: [appColorSettingsLocaleCollection],
    });
