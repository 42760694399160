import { EntityReference, PropertiesOrBuilders, buildCollection, buildEntityCallbacks } from "firecms";
import { getLocales } from "../../locale_config";
import { collectionVersionedPrefix } from "../data_version";
import { NomadCloudTrip, NomadCloudTripLocale } from "../types";
import { itineraryCollection } from "./itinerary_collection";
import CustomMultiDatePicker from "../custom_fields_v2/CustomMultiDatePicker";

const tripProperties = {
    active: {
        name: "Active",
        dataType: "boolean",
        description: "Is the Trip Active?",
    },
    featured: {
        name: "Featured ⭐",
        description: "Is the Trip Featured / Shown on the landing page?",
        dataType: "boolean",
    },
    title: {
        name: "Title",
        description: "Trip Title (Max 120 chars)",
        validation: {
            required: true,
            max: 120,
        },
        dataType: "string",
    },

    description: {
        name: "Description",
        dataType: "string",
        description: "Trip Description (Max 3000 chars)",
        validation: {
            required: true,
            max: 3000,
        },
        multiline: true,
        markdown: true,
        columnWidth: 300,
    },
    destinations: {
        name: "Destinations",
        description: "Trip Destinations are auto-filled from the Days of Itineraries",
        dataType: "array",
        of: {
            dataType: "reference",
            path: `${collectionVersionedPrefix()}/destinations`,
            previewProperties: ["name"],
        },
        readOnly: true,
        disabled: true,
    },
    coverImageFirebaseUrl: {
        name: "Cover Image",
        description: "Image shown in the App",
        validation: {
            required: true,
        },
        dataType: "string",
        storage: {
            storagePath: "images/trips",
            acceptedFiles: ["image/*"],
        },
    },
    coverImageFirebaseSignedUrl: {
        name: "Cover Image Url",
        dataType: "string",
        readOnly: true,
        disabled: {
            hidden: true,
        },
    },
    categories: {
        name: "Categories",
        description: "Trip Categories (Follow Guidelines)",
        dataType: "array",
        of: {
            dataType: "reference",
            path: `${collectionVersionedPrefix()}/tripCategories`,
            previewProperties: ["name"],
        },
    },
    activities: {
        name: "Activities",
        description: "Trip Activities (Follow Guidelines)",
        dataType: "array",
        of: {
            dataType: "reference",
            path: `${collectionVersionedPrefix()}/tripActivities`,
            previewProperties: ["name"],
        },
    },
    guides: {
        dataType: "array",
        name: "Guides",
        description: "Guides of this Trip",
        validation: {
            required: true,
        },
        of: {
            dataType: "reference",
            path: `${collectionVersionedPrefix()}/guides`,
            previewProperties: ["displayName"],
        },
    },
    products: {
        dataType: "array",
        name: "Relevant Products",
        description: "Relevant Products",
        of: {
            dataType: "reference",
            path: `${collectionVersionedPrefix()}/products`,
            previewProperties: ["title"],
        },
    },
    privateUsers: {
        dataType: "array",
        name: "Private Users",
        description: "Private Users",
        of: {
            dataType: "reference",
            path: `${collectionVersionedPrefix()}/users`,
            previewProperties: ["displayName"],
        },
    },
    galleryFirebaseUrlArray: {
        dataType: "array",
        name: "Gallery",
        description: "Each Trip should have some nice Images, from past Trips ideally",
        validation: {
            required: true,
        },
        of: {
            name: "Image",
            dataType: "string",
            storage: {
                storagePath: "images/trip_galleries",
                acceptedFiles: ["image/*"],
            },
        },
    },
    galleryFirebaseSignedUrlArray: {
        name: "Gallery (signed)",
        dataType: "array",
        of: {
            dataType: "string",
        },
        disabled: {
            hidden: true,
        },
    },
    downloadsFirebaseUrlArray: {
        dataType: "array",
        name: "Downloads",
        description: "If you need files to be downloadable, put those here",
        of: {
            name: "File",
            dataType: "string",
            storage: {
                storagePath: "downloads",
            },
        },
    },
    downloadsFirebaseSignedUrlArray: {
        name: "Downloads (signed)",
        dataType: "array",
        of: {
            dataType: "string",
        },
        disabled: {
            hidden: true,
        },
    },
    difficultyLevel: {
        name: "Difficulty",
        description: "1 = easy; 5 = extreme. (Follow Guidelines)",
        dataType: "number",
        validation: {
            required: true,
            min: 1,
            max: 5,
        },
    },
    tips: {
        name: "Tips",
        dataType: "array",
        description: "Tips. (Follow Guidelines)",
        of: {
            dataType: "string",
        },
    },
    beforeYouGo: {
        name: "Before You Go",
        dataType: "string",
        description: "Before You Go. (Follow Guidelines)",
        multiline: true,
        markdown: true,
        columnWidth: 300,
    },
    mapLocation: {
        name: "Map - Location",
        dataType: "string",
        description: "E.g. '40.744181560594114, -73.98645340945886' (Use Google Maps to find out)",
        validation: {
            required: true,
        },
    },
    mapZoom: {
        name: "Map - Zoom",
        dataType: "number",
        validation: { required: true, moreThan: 0, lessThan: 21 },
        description: "Start with 10 (You can adjust later; 1-20)",
    },
    tripCode: {
        dataType: "string",
        name: "Trip Code",
    },
    itineraryCount: {
        description: "Number of Itineraries in this Trip (auto-calculated)",
        name: "Itinerary Count",
        dataType: "number",
        readOnly: true,
    },
    priceStartsAt: {
        description: "Regular pricing for 1 Adult. Is displayed on home screen.",
        name: "Price Starts At",
        dataType: "number",
    },
    deal: {
        description: "Deal name, such as '50% OFF' or 'SUMMER DEALS'. Is displayed on home screen.",
        name: "Deal",
        dataType: "string",
    },
    dealColor: {
        description: "Please use HTML Color. Is displayed on home screen.",
        name: "Deal Color",
        dataType: "string",
    },
    modifiedAt: {
        name: "Modified At",
        dataType: "date",
        autoValue: "on_update",
        readOnly: true,
    },
    modifiedBy: {
        dataType: "reference",
        name: "Modified By",
        path: `${collectionVersionedPrefix()}/users`,
        previewProperties: ["displayName"],
        readOnly: true,
    },
    createdAt: {
        name: "Created At",
        dataType: "date",
        autoValue: "on_create",
        readOnly: true,
    },
    createdBy: {
        dataType: "reference",
        name: "Created By",
        path: `${collectionVersionedPrefix()}/users`,
        previewProperties: ["displayName"],
        readOnly: true,
    },
} as PropertiesOrBuilders<NomadCloudTrip>;

export const tripCollection = () => {
    return buildCollection<NomadCloudTrip>({
        path: `${collectionVersionedPrefix()}/trips`,
        name: "Trips",
        singularName: "Trip",
        group: "Main",
        description: "Trips",
        textSearchEnabled: false,
        icon: "Flight",
        subcollections: [tripLocaleCollection, itineraryCollection()],
        properties: tripProperties,
        callbacks: buildEntityCallbacks({
            onPreSave: async ({ values, entityId, context }) => {
                if (!entityId) {
                    // creation
                    values.createdBy = new EntityReference(
                        context.authController.user!.uid,
                        `${collectionVersionedPrefix()}/users`
                    );
                }

                values.modifiedBy = new EntityReference(
                    context.authController.user!.uid,
                    `${collectionVersionedPrefix()}/users`
                );
                return values;
            },
        }),
    });
};

export const tripLocaleCollection = buildCollection<NomadCloudTripLocale>({
    path: "translation",
    customId: getLocales(),
    name: "Translation",
    singularName: "Translation",
    properties: {
        title: tripProperties.title,
        description: tripProperties.description,
        tips: tripProperties.tips,
        beforeYouGo: tripProperties.beforeYouGo,
        deal: tripProperties.deal,

        modifiedAt: tripProperties.modifiedAt,
        modifiedBy: tripProperties.modifiedBy,
        createdAt: tripProperties.createdAt,
        createdBy: tripProperties.createdBy,
    },
});
