import { EnumValues } from "firecms";
import { countries } from "./ContentManagement/enums";

export const getCountries = (): EnumValues => {
    const cntrs = {} as { [x: string]: string };
    const countryCodeArray = import.meta.env.VITE_OPERATOR_COUNTRIES
        ? (import.meta.env.VITE_OPERATOR_COUNTRIES as string).split("|")
        : [];

    for (const countryCode of countryCodeArray) {
        const country = countries.find((country) => country.id === countryCode);
        if (country) {
            cntrs[countryCode] = country.label;
        } else {
            // console.log(`Unknown language code: ${langCode} (skipping)`);
        }
    }
    return cntrs;
};
