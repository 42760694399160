import { getAnalytics, logEvent } from "firebase/analytics";
import { User as FirebaseUser } from "firebase/auth";
import {
    Authenticator,
    CMSView,
    FirebaseCMSApp
} from "firecms";
import { Fragment, createElement, useCallback } from "react";
import { Popup } from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { SettingsView } from "./views/SettingsView";
import { Info, Public, Translate } from "@mui/icons-material";
import { Chip, IconButton, Tooltip, Typography } from "@mui/material";

import * as pckg from "../../package.json";
import { firebaseConfig } from "../firebase_config";
import logo from "./images/logo.png";

import { textSearchController } from "./text_search";

import "@fontsource/ibm-plex-mono";
import jwtDecode from "jwt-decode";
import { getCountries } from "../country_config";
import "typeface-rubik";
import { getLocales } from "../locale_config";
import { ContentManagementLoginView } from "./ContentManagementLoginView";
import { NomadCloudBrand } from "./brand";
import { accommodationFeatureCollection } from "./collections/accommodationFeature_collection";
import { countryCollection } from "./collections/country_collection";
import {
    customCollectionOverrideHandler
} from "./collections/custom_collection_resolver";
import { destinationCollection } from "./collections/destination_collection";
import { guideCollection } from "./collections/guide_collection";
import { tripActivityCollection } from "./collections/tripActivity_collection";
import { tripCategoryCollection } from "./collections/tripCategory_collection";
import { tripCollection } from "./collections/trip_collection";
import { userCollection } from "./collections/user_collection";
import { currentDataVersion } from "./data_version";
import { accommodationCollection } from "./collections/accommodation_collection";
import { productCollection } from "./collections/product_collection";
import { partnershipsCollection } from "./collections/partnership_collection";
import { voucherTypeCollection } from "./collections/voucherType_collection";
import { vouchersCollection } from "./collections/vouchers_collection";
import { bookingsCollection } from "./collections/booking_collection";

function ContentManagementAppV1() {
    const localesInformationText = Object.values(getLocales()).join(" | ");
    const countryInformationText = Object.values(getCountries()).join(" | ");

    const countryInformation = (
        <Tooltip
            title={countryInformationText}>
            <Chip icon={<Public />} label={countryInformationText.length > 80 ? `${countryInformationText.substring(0, 80)} ...` : countryInformationText  } variant="filled" color="primary"/>
        </Tooltip>
    );

    const localesInformation = (
        <Tooltip
            title={localesInformationText}>
            <Chip icon={<Translate />} label={localesInformationText.length > 80 ? `${localesInformationText.substring(0, 80)} ...` : localesInformationText} variant="filled" color="primary"/>
        </Tooltip>
    );

    const popUp = (
        <Popup trigger={<IconButton><Info /></IconButton>} position="bottom center">
            <Typography variant="button">IDE Version </Typography>
            <Typography variant="caption">{`${pckg.version}`}</Typography>
            <br />
            <Typography variant="button">Environment </Typography>
            <Typography variant="caption">{`${firebaseConfig.projectId}`}</Typography>
            <br />
            <Typography variant="button">Data Version </Typography>
            <Typography variant="caption">{`${currentDataVersion}`}</Typography>
            <br />
        </Popup>
    );

    const popupElement = createElement("span", null, popUp);
    const countryInformationElement = createElement("span", null, countryInformation);
    const localesInformationElement = createElement("span", null, localesInformation);

    const element = createElement(
        Fragment,
        null,
        countryInformationElement,
        localesInformationElement,
        popupElement,
    )

    const onFirebaseInit = (config: object) => {
        // Just calling analytics enables screen tracking
        getAnalytics();
    };

    const myAuthenticator: Authenticator<FirebaseUser> = useCallback(async ({
                                                                                user,
                                                                                authController
                                                                            }) => {

        const claims = jwtDecode(await user!.getIdToken()) as { [key: string]: string | boolean; };
        const admin = claims.admin;

        if (!admin) {
            throw Error("Error");
        }

        authController.setExtra(claims);

        return true;
    }, []);

    const customViews: CMSView[] = [{
        path: "settings",
        name: "Settings",
        group: "Main",
        description: "Settings",
        view: <SettingsView/>
    }];

    const collections = [
        bookingsCollection,

        tripCollection(),
        destinationCollection(),
        accommodationCollection(),
        countryCollection(),

        productCollection(),
        partnershipsCollection,
        voucherTypeCollection(),
        vouchersCollection,

        guideCollection(),
        userCollection(),

        accommodationFeatureCollection(),
        tripActivityCollection(),
        tripCategoryCollection()

    ];

    const onAnalyticsEvent = useCallback((event: string, data?: object) => {
        const analytics = getAnalytics();
        logEvent(analytics, event, data);
    }, []);

    const brand: NomadCloudBrand = {
        name: `${import.meta.env.VITE_OPERATOR_NAME}: Content Management`,
        primaryColor: `${import.meta.env.VITE_OPERATOR_COLOR_PRIMARY}`,
        secondaryColor: `${import.meta.env.VITE_OPERATOR_COLOR_SECONDARY}`,
    }

    return <FirebaseCMSApp
        name={brand.name}
        authentication={myAuthenticator}
        signInOptions={[
            "google.com"
        ]}
        textSearchController={textSearchController}
        allowSkipLogin={false}
        logo={logo}
        collections={(params) => collections}
        collectionOverrideHandler={customCollectionOverrideHandler}
        firebaseConfig={firebaseConfig}
        onFirebaseInit={onFirebaseInit}
        toolbarExtraWidget={element}
        LoginView={ContentManagementLoginView}
        onAnalyticsEvent={onAnalyticsEvent}
        primaryColor={brand.primaryColor}
        secondaryColor={brand.secondaryColor}
        views={customViews}
    />;

}

export default ContentManagementAppV1;
