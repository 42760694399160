import { EntityReference, buildCollection, buildEntityCallbacks } from "firecms";
import { collectionVersionedPrefix } from "../data_version";
import { NomadCloudUser, NomadCloudUserBooking } from "../types";

export const userCollection = () => {
    return buildCollection<NomadCloudUser>({
        path: `${collectionVersionedPrefix()}/users`,
        name: "Users",
        singularName: "User",
        group: "HR",
        description: "Users",
        textSearchEnabled: false,
        icon: "AccessibilityNew",
        callbacks: buildEntityCallbacks({
            onPreSave: async ({ values, entityId, context }) => {
                values.modifiedBy = new EntityReference(
                    context.authController.user!.uid,
                    `${collectionVersionedPrefix()}/users`
                );
                return values;
            },
        }),
        subcollections:[buildCollection<NomadCloudUserBooking>({
                path: "bookings",
                name: "Bookings",
                singularName: "Booking",
                properties: {
                    createdAt: {
                        name: "Created At",
                        dataType: "date",
                        autoValue: "on_create",
                        readOnly: true,
                    },
                    modifiedAt: {
                        name: "Modified At",
                        dataType: "date",
                        autoValue: "on_update",
                        readOnly: true,
                    },
                    bookingData: {
                        name: "Booking Data",
                        dataType: "map",
                        properties: {
                            tripId: {
                                dataType: "string",
                                name: "Trip Id",
                                disabled: {
                                    hidden: true,
                                },
                            },
                            itineraryId: {
                                dataType: "string",
                                name: "Itinerary Id",
                                disabled: {
                                    hidden: true,
                                },
                            },
                            trip: {
                                dataType: "reference",
                                name: "Trip",
                                description: "Trip",
                                path: `${collectionVersionedPrefix()}/trips`,
                                previewProperties: ["title"],
                                readOnly: true,
                            },
                            itineraryName: {
                                dataType: "string",
                                name: "Itinerary Name"
                            },
                            startDate: {
                                dataType: "string",
                                name: "Start Date"
                            },
                            endDate: {
                                dataType: "string",
                                name: "End Date"
                            },
                            note: {
                                dataType: "string",
                                name: "Note"
                            },
                            passengers: {
                                name: "Passengers",
                                dataType: "map",
                                properties: {
                                    regular: {
                                        name: "Regular",
                                        description: "Regular",
                                        dataType: "number"
                                    },
                                    kid: {
                                        name: "Kid",
                                        description: "Kid",
                                        dataType: "number"
                                    },
                                    senior: {
                                        name: "Senior",
                                        description: "Senior",
                                        dataType: "number"
                                    }
                                }
                            },
                            products: {
                                dataType: "array",
                                name: "Products",
                                description: "Products",
                                of: {
                                    dataType: "map",
                                    properties: {
                                        voucher: {
                                            name: "Product",
                                            description: "Product",
                                            dataType: "reference",
                                            path: `${collectionVersionedPrefix()}/products`,
                                            previewProperties: ["title"],
                                        },
                                        count: {
                                            name: "Count",
                                            description: "Count",
                                            dataType: "number",
                                            validation: {
                                                min: 0,
                                                max: 50
                                            }
                                        },
                                    },
                                    expanded: true,
                                },
                            },
                        },
                        previewProperties: ["tripTitle"]
                    },
                    price: {
                        dataType: "number",
                        name: "Price"
                    },
                    status: {
                        dataType: "string",
                        enumValues: {
                            to_be_confirmed: "To Be Confirmed",
                            confirmed: "To Be Confirmed",
                            purchased: "Purchased",
                            refunded: "Refunded",
                            canceled: "Canceled"
                        }
                    },
                    transactions: {
                        name: "Transactions",
                        dataType: "array",
                        of: {
                            dataType: "string"
                        }
                    }
                },
            })
        ],
        properties: {
            active: {
                name: "Active",
                dataType: "boolean",
            },
            displayName: {
                name: "Name",
                validation: { required: true },
                dataType: "string",
                // readOnly: true,
            },
            favoriteTrips: {
                dataType: "array",
                name: "Favorite Trips",
                of: {
                    dataType: "reference",
                    path: `${collectionVersionedPrefix()}/trips`,
                    previewProperties: ["title"],
                },
            },
            notes: {
                name: "Notes",
                dataType: "string",
                multiline: true,
            },
            email: {
                name: "Email",
                // validation: { required: true },
                dataType: "string",
            },
            joinedAt: {
                name: "Joined At",
                dataType: "date",
                readOnly: true,
            },
            loggedInAt: {
                name: "Last Login",
                dataType: "date",
                readOnly: true,
            },
            phone: {
                dataType: "string",
                name: "Phone (Contact)",
            },
            photoUrl: {
                dataType: "string",
                name: "Photo URL",
            },
            modifiedAt: {
                name: "Modified At",
                dataType: "date",
                autoValue: "on_update",
                readOnly: true,
            },
            modifiedBy: {
                dataType: "reference",
                name: "Modified By",
                path: `${collectionVersionedPrefix()}/users`,
                previewProperties: ["displayName"],
                readOnly: true,
            },
        },
    });
};
