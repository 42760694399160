/* eslint-disable no-tabs */
import { FieldDescription, FieldProps } from "firecms";
import { HexColorInput, HexColorPicker } from "react-colorful";

export default function CustomColorField({
                                                 property,
                                                 value,
                                                 setValue,
                                                 setFieldValue, // use this function to update a different field
                                                 customProps,
                                                 touched,
                                                 error,
                                                 isSubmitting,
                                                 context, // the rest of the entity values here
                                                 ...props
                                             }: FieldProps<string, any>) {

    return (
        <>
        <HexColorPicker color={value ?? "#888888"} onChange={(color) =>
                        {
                            return setValue(color);
                        }
					} />
        <HexColorInput color={value ?? "#888888"} onChange={(color) =>
                        {
                            return setValue(color);
                        }} />
            <FieldDescription property={property}/>
        </>

    );

}