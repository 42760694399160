import { buildCollection, buildProperty } from "firecms";
import { collectionVersionedPrefix } from "../data_version";
import { countries } from "../enums";
import { getLocales } from "../../locale_config";


export const partnershipLocaleCollection = buildCollection({
    path: "translation",
    customId: getLocales(),
    name: "Translation",
    singularName: "Translation",
    properties: {
        partnershipType: {
            name: "Partnership Type (e.g Restaurant)",
            dataType: "string",
        },
        partnershipClaim: {
            name: "Partnership Claim",
            dataType: "string",
        },
    },
});

export const partnershipsCollection = buildCollection({
    path: `${collectionVersionedPrefix()}/partnerships`,
    name: "Partnerships",
    singularName: "Partnership",
    group: "Ecommerce",
    description: "Partnerships",
    textSearchEnabled: false,
    icon: "BusinessCenter",
    subcollections: [partnershipLocaleCollection],
    properties: {
        active: {
            name: "Active",
            dataType: "boolean",
        },
        name: {
            name: "Name",
            dataType: "string",
            validation: { required: true },
        },
        partnershipType: {
            name: "Partnership Type (e.g Restaurant)",
            dataType: "string",
        },
        partnershipClaim: {
            name: "Partnership Claim",
            dataType: "string",
        },
        logoFirebaseUrl: buildProperty({
            name: "Logo",
            dataType: "string",
            storage: {
                storagePath: "images/partnership_logos",
                acceptedFiles: ["image/*"],
                metadata: {
                    cacheControl: "max-age=1000000"
                }
            },
            validation: { required: true },
        }),
        logoFirebaseSignedUrl: {
            name: "Logo (signed)",
            dataType: "string",
            readOnly: true
          },
        notes: {
            name: "Notes",
            dataType: "string",
            multiline: true,
        },
        startDate: buildProperty({
            name: "Contract Start Date",
            dataType: "date",
            expanded: true,
            mode: "date"
        }),
        endDate: buildProperty({
            name: "Contract End Date",
            dataType: "date",
            expanded: true,
            mode: "date"
        }),
        contact: buildProperty({
            name: "Contact",
            dataType: "map",
            properties: {
                name: {
                    name: "Name",
                    description: "Name",
                    dataType: "string",
                    validation: { required: true },
                },
                email: {
                    name: "Email",
                    description: "Email",
                    dataType: "string",
                    email: true,
                    validation: { required: true },
                },
                whatsapp: {
                    name: "Whatsapp",
                    description: "Whatsapp",
                    dataType: "string"
                },
                phone: {
                    name: "Phone",
                    description: "Phone",
                    dataType: "string"
                }
            },
            expanded: true,
            validation: { required: true },
        }),
        officeAddress: buildProperty({
            name: "Office Address",
            dataType: "map",
            properties: {
                name: {
                    name: "Name",
                    description: "Name",
                    dataType: "string",
                    validation: { required: true },
                },
                email: {
                    name: "Email",
                    description: "Email",
                    dataType: "string",
                    email: true,
                    validation: { required: true },
                },
                whatsapp: {
                    name: "Whatsapp",
                    description: "Whatsapp",
                    dataType: "string",
                },
                phone: {
                    name: "Phone",
                    description: "Phone",
                    dataType: "string",
                    validation: { required: true },
                },
                addressLine1: {
                    name: "Street",
                    description: "Street",
                    dataType: "string",
                    validation: { required: true },
                },
                addressLine2: {
                    name: "P.O.Box, Floor, etc",
                    description: "P.O.Box, Floor, etc",
                    dataType: "string",
                },
                zip: {
                    name: "ZIP/Postal Code",
                    description: "ZIP/Postal Code",
                    dataType: "string",
                    validation: { required: true },
                },
                city: {
                    name: "Town/City",
                    description: "Town/City",
                    dataType: "string",
                    validation: { required: true },
                },
                country: {
                    name: "Country",
                    description: "Country",
                    dataType: "string",
                    enumValues: countries,
                    validation: { required: true },
                },
            },
            expanded: true,
        }),
        taxAddress: buildProperty({
            name: "Tax Information",
            dataType: "map",
            properties: {
                name: {
                    name: "Name",
                    description: "Name",
                    dataType: "string",
                    validation: { required: true },
                },
                phone: {
                    name: "Phone",
                    description: "Phone",
                    dataType: "string",
                    validation: { required: true },
                },
                businessId: {
                    name: "Business ID",
                    description: "Business ID",
                    dataType: "string",
                    validation: { required: true },
                },
                vatId: {
                    name: "VAT ID",
                    description: "VAT ID",
                    dataType: "string",
                },
                addressLine1: {
                    name: "Street",
                    description: "Street",
                    dataType: "string",
                    validation: { required: true },
                },
                addressLine2: {
                    name: "P.O.Box, Floor, etc",
                    description: "P.O.Box, Floor, etc",
                    dataType: "string",
                },
                zip: {
                    name: "ZIP/Postal Code",
                    description: "ZIP/Postal Code",
                    dataType: "string",
                    validation: { required: true },
                },
                city: {
                    name: "Town/City",
                    description: "Town/City",
                    dataType: "string",
                    validation: { required: true },
                },
                country: {
                    name: "Country",
                    description: "Country",
                    dataType: "string",
                    enumValues: countries,
                    validation: { required: true },
                }
            },
            expanded: true,
        }),
        modifiedAt: {
            name: "Modified At",
            dataType: "date",
            autoValue: "on_update",
            readOnly: true,
        },
        createdAt: {
            name: "Created At",
            dataType: "date",
            autoValue: "on_create",
            readOnly: true,
        },
    },
});

