import { EntityReference, PropertiesOrBuilders, buildCollection, buildEntityCallbacks } from "firecms";
import { getLocales } from "../../locale_config";
import { collectionVersionedPrefix } from "../data_version";
import { NomadCloudItinerary, NomadCloudItineraryLocale } from "../types";
import CustomMultiDatePicker from "../custom_fields_v2/CustomMultiDatePicker";

const itineraryProperties = {
    active: {
        name: "Active",
        description: "Is the Itinerary Active?",
        dataType: "boolean",
    },
    featured: {
        name: "Featured ⭐",
        description: "Featured Itineraries are pre-selected",
        dataType: "boolean",
    },
    name: {
        name: "Name",
        description: "Itinerary name (Max 12 chars). Copy Trip title if there's no special meaning to this Itinerary.",
        validation: {required: true, max: 12 },
        dataType: "string",
    },
    description: {
        name: "Description",
        description: "Itinerary description (Max 250 chars)",
        validation: { required: true, max: 250 },
        dataType: "string",
        multiline: true,
        markdown: true,
    },
    dayCount: {
        name: "Day Count",
        description: "Day Count",
        dataType: "number",
        validation: {
            required: true,
            moreThan: 0,
            lessThan: 181,
        },
    },
    includedVouchers: {
        dataType: "array",
        name: "Included Vouchers",
        description: "Included Vouchers",
        of: {
            dataType: "map",
            properties: {
                voucher: {
                    name: "Voucher Type",
                    description: "Voucher Type",
                    dataType: "reference",
                    path: `${collectionVersionedPrefix()}/voucherTypes`,
                    previewProperties: ["title"],
                },
                count: {
                    name: "Count",
                    description: "Count",
                    dataType: "number",
                    validation: {
                        min: 1,
                        max: 10
                    }
                },
            },
            expanded: true,
        },
    },
    accommodation: {
        dataType: "array",
        name: "Accommodation",
        of: {
            dataType: "reference",
            path: `${collectionVersionedPrefix()}/accommodation`,
            previewProperties: ["name"],
        },
    },
    segments: {
        name: "Segments (Days/Parts of Days/Weeks/..)",
        description: "Describe segments here (Days/Parts of Days/Weeks/..)",
        dataType: "array",
        of: {
            dataType: "map",
            properties: {
                segmentName: {
                    name: "Segment Name",
                    dataType: "string",
                    validation: { required: true, max: 12 },
                    description: "E.g. 'Day 1', 'Morning', 'Week 2' (Max 12 chars)",
                    longDescription: "E.g. 'Day 1', 'Morning', 'Week 2'",
                },
                destination: {
                    dataType: "reference",
                    description: "A Destination where the segment takes place",
                    validation: { required: true },
                    name: "Destination",
                    path: `${collectionVersionedPrefix()}/destinations`,
                    previewProperties: ["name"],
                },
                name: {
                    name: "Title",
                    description: "A Title for the Segment, e.g. Landing in ATH Airport (Max 30 chars)",
                    validation: { required: true, max: 30 },
                    dataType: "string",
                },
                description: {
                    name: "Description",
                    description: "A Description for the Segment (Max 300 chars)",
                    validation: { required: true, max: 300 },
                    dataType: "string",
                    multiline: true,
                    markdown: true,
                },
                timeStart: {
                    name: "Starts At",
                    dataType: "string",
                    description: "E.g. 10:00am (Optional)",
                },
                timeEnd: {
                    name: "Ends At",
                    dataType: "string",
                    description: "E.g. 5:30pm (Optional)",
                },
                mapLocation: {
                    name: "Map - Location",
                    dataType: "string",
                    description: "E.g. 40.744181560594114, -73.98645340945886 (Use Google Maps to find out) Optional.",
                },
                mapZoom: {
                    name: "Map - Zoom",
                    dataType: "number",
                    validation: { moreThan: 0, lessThan: 21 },
                    description: "Start with 10 (You can adjust later; 1-20) Optional.",
                },
                segmentImageFirebaseUrl: {
                    name: "Segment Image",
                    dataType: "string",
                    validation: { required: true },
                    description: "Pick nice picture that represents the segment",
                    storage: {
                        storagePath: "images/trip_itinerary_segments",
                        acceptedFiles: ["image/*"],
                    },
                },
                segmentImageFirebaseSignedUrl: {
                    name: "Segment Image (signed)",
                    dataType: "string",
                    readOnly: true,
                    disabled: {
                        hidden: true,
                    },
                },
            },
            previewProperties: ["segmentName"],
        },
    },
    maxPersons: {
        name: "Max Persons",
        description: "Max number of persons (Optional)",
        dataType: "number",
        validation: {
            min: 1,
        },
    },
    minPersons: {
        name: "Min Persons",
        description: "Min number of persons (Optional)",
        dataType: "number",
        validation: {
            min: 0,
        },
    },
    tripIncludes: {
        name: "Trip Includes",
        description: "What Trip Includes (Follow Guidelines)",
        dataType: "array",
        of: {
            dataType: "string",
        },
    },
    availableDates: {
        name: "Available Dates",
        description: "Available Dates",
        dataType: "array",
        of: {
            dataType: "string",
        },
        Field: CustomMultiDatePicker,
    },
    itineraryCode: {
        dataType: "string",
        name: "Itinerary Code",
    },
    prices: {
        name: "Prices",
        description: "Prices per Person type.",
        longDescription: "Prices per Person type.",
        dataType: "map",
        properties: {
            regular: {
                name: "Regular",
                description: "Regular",
                dataType: "number",
                validation: {
                    required: true,
                },
            },
            kid: {
                name: "Kid",
                description: "Kid",
                dataType: "number",
            },
            senior: {
                name: "Senior",
                description: "Senior",
                dataType: "number",
            },
        },
        previewProperties: ["regular"],
    },
    priceNote: {
        dataType: "string",
        name: "Price Note",
    },
    discounts: {
        name: "Discounts",
        description: "Discounts per amount of persons.",
        longDescription: "Discounts per amount of persons.",
        dataType: "array",
        of: {
            dataType: "map",
            properties: {
                minimumPersons: {
                    name: "Minimum Persons",
                    description: "Minimum Persons",
                    dataType: "number",
                    validation: {
                        required: true,
                        min: 1,
                        max: 50
                    },
                },
                discountType: {
                    name: "Discount Type",
                    description: "Discount Type",
                    dataType: "string",
                    validation: {
                        required: true,
                    },
                    enumValues: {
                        percentage: "Percentage",
                        fixed: "Fixed Price",
                    },
                },
                discountPercentage: {
                    name: "Percentage (1-100)",
                    description: "Percentage (0-100)",
                    validation: {
                        min: 1,
                        max: 100,
                    },
                    dataType: "number",
                },
                discountFixed: {
                    name: "Fixed",
                    description: "Fixed",
                    dataType: "number",
                    validation: {
                        min: 1,
                    },
                },
            },
        },
    },

    saleDiscount: {
        name: "Sale Discount",
        description: "Fill with Dummy information. ENABLING makes the sale discount active.",
        longDescription: "Fill with Dummy information. ENABLING makes the sale discount active.",
        dataType: "map",
        properties: {
            enabled: {
                name: "Enabled",
                description: "Enabled",
                dataType: "boolean",
            },
            minimumPersons: {
                name: "Minimum Persons",
                description: "Minimum Persons",
                dataType: "number",
                validation: {
                    required: true,
                    min: 1,
                    max: 50
                },
            },
            discountType: {
                name: "Discount Type",
                description: "Discount Type",
                dataType: "string",
                validation: {
                    required: true,
                },
                enumValues: {
                    percentage: "Percentage",
                    fixed: "Fixed Price",
                },
            },
            discountPercentage: {
                name: "Percentage (1-100)",
                description: "Percentage (0-100)",
                validation: {
                    min: 1,
                    max: 100,
                    required: true,
                },
                dataType: "number",
            },
            discountFixed: {
                name: "Fixed",
                description: "Fixed",
                dataType: "number",
                validation: {
                    min: 1,
                    required: true,
                },
            },
            startsAt: {
                name: "Starts At",
                dataType: "date",
                mode: "date",
                validation: {
                    required: true,
                },
            },
            endsAt: {
                name: "Ends At",
                dataType: "date",
                mode: "date",
                validation: {
                    required: true,
                },
            },
            saleDiscountText: {
                dataType: "string",
                name: "Sale Discount Text",
                validation: {
                    required: true,
                },
            },
            saleDiscountColor: {
                dataType: "string",
                name: "Sale Discount Color",
                validation: {
                    required: true,
                },
            },
        },
        previewProperties: ["startsAt"],
    },

    modifiedAt: {
        name: "Modified At",
        dataType: "date",
        autoValue: "on_update",
        readOnly: true,
    },
    modifiedBy: {
        dataType: "reference",
        name: "Modified By",
        path: `${collectionVersionedPrefix()}/users`,
        previewProperties: ["displayName"],
        readOnly: true,
    },
    createdAt: {
        name: "Created At",
        dataType: "date",
        autoValue: "on_create",
        readOnly: true,
    },
    createdBy: {
        dataType: "reference",
        name: "Created By",
        path: `${collectionVersionedPrefix()}/users`,
        previewProperties: ["displayName"],
        readOnly: true,
    }

} as PropertiesOrBuilders<NomadCloudItinerary>;

export const itineraryCollection = () => {
    return buildCollection<NomadCloudItinerary>({
        path: "itineraries",
        name: "Itineraries",
        singularName: "Itinerary",
        group: "Main",
        description: "Itinerary",
        textSearchEnabled: false,
        icon: "AirlineStops",
        subcollections: [itineraryLocaleCollection],
        properties: itineraryProperties,
        callbacks: buildEntityCallbacks({
            onPreSave: async ({ values, entityId, context }) => {
                if (!entityId) {
                    // creation
                    values.createdBy = new EntityReference(
                        context.authController.user!.uid,
                        `${collectionVersionedPrefix()}/users`
                    );
                }

                values.modifiedBy = new EntityReference(
                    context.authController.user!.uid,
                    `${collectionVersionedPrefix()}/users`
                );
                return values;
            },
        }),
    });
};

export const itineraryLocaleCollection = buildCollection<NomadCloudItineraryLocale>({
    path: "translation",
    customId: getLocales(),
    name: "Translation",
    singularName: "Translation",
    properties: {
        name: itineraryProperties.name,
        description: itineraryProperties.description,

        segments: {
            name: "Segments (Days/Parts of Days/Weeks/..)",
            description: "Describe segments here (Days/Parts of Days/Weeks/..)",
            dataType: "array",
            of: {
                dataType: "map",
                properties: {
                    segmentName: {
                        name: "Segment Name",
                        dataType: "string",
                        validation: { required: true, max: 12 },
                        description: "E.g. 'Day 1', 'Morning', 'Week 2' (Max 12 chars)",
                        longDescription: "E.g. 'Day 1', 'Morning', 'Week 2'",
                    },
                    name: {
                        name: "Title",
                        description: "A Title for the Segment (Max 30 chars)",
                        validation: { required: true, max: 30 },
                        dataType: "string",
                    },
                    description: {
                        name: "Description",
                        description: "A Description for the Segment (Max 300 chars)",
                        validation: { required: true, max: 300 },
                        dataType: "string",
                        multiline: true,
                        markdown: true,
                    },
                },
                previewProperties: ["segmentName"],
            },
        },

        priceNote: itineraryProperties.priceNote,
        modifiedAt: itineraryProperties.modifiedAt,
        modifiedBy: itineraryProperties.modifiedBy,
        createdAt: itineraryProperties.createdAt,
        createdBy: itineraryProperties.createdBy,
    }
});