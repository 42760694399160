/* eslint-disable no-tabs */
import { FieldDescription, FieldProps } from "firecms";
import FontPicker from "font-picker-react";

export default function CustomFontField({
                                                 property,
                                                 value,
                                                 setValue,
                                                 setFieldValue, // use this function to update a different field
                                                 customProps,
                                                 touched,
                                                 error,
                                                 isSubmitting,
                                                 context, // the rest of the entity values here
                                                 ...props
                                             }: FieldProps<string, any>) {

    return (
        <>
            <FontPicker
					apiKey={`${import.meta.env.VITE_GOOGLE_FONTS_API_KEY}`}
					activeFontFamily={value ?? "Arimo"}
                    onChange={(nextFont) =>
                        {
                            return setValue(nextFont.family);
                        }
					}
				/>

            <FieldDescription property={property}/>
        </>

    );

}