import { PropertiesOrBuilders, buildCollection } from "firecms";
import { getLocales } from "../../locale_config";
import CustomFontField from "../custom_fields_v2/CustomFontField";
import { collectionVersionedPrefix } from "../data_version";
import { NomadCloudAppGeneralSettings, NomadCloudAppGeneralSettingsLocale } from "./general.settings.types";
import { getCountries } from "../../country_config";

export const appGeneralSettingsProperties = {
    font: {
        name: "Font",
        description: "Font",
        dataType: "string",
        Field: CustomFontField,
    },
    mainButtonImageFirebaseUrl: {
        name: "Main Button Image",
        description: "Main Button Image",
        dataType: "string",
        storage: {
            storagePath: "images/main",
            acceptedFiles: ["image/*"],
            metadata: {
                cacheControl: "max-age=1000000",
            },
        },
    },
    mainButtonImageFirebaseSignedUrl: {
        name: "Main Button Image (signed)",
        dataType: "string",
        readOnly: true,
        disabled: {
            hidden: true,
        },
    },
    pricingInformation: {
        name: "Pricing Information",
        description: "Pricing Information",
        markdown: true,
        dataType: "string",
    },
    // mainButtonImageDarkFirebaseUrl: {
    //     name: "Main Button Image (Dark theme)",
    //     description: "Main Button Image(Dark theme)",
    //     dataType: "string",
    //     storage: {
    //         storagePath: "images/main",
    //         acceptedFiles: ["image/*"],
    //         metadata: {
    //             cacheControl: "max-age=1000000",
    //         },
    //     },
    // },
    // mainButtonImageDarkFirebaseSignedUrl: {
    //     name: "Main Button Image (Dark theme) (signed)",
    //     dataType: "string",
    //     readOnly: true,
    //     disabled: {
    //         hidden: true,
    //     },
    // },
    quoteImageFirebaseUrl: {
        name: "Quote Image",
        description: "Quote Image",
        dataType: "string",
        storage: {
            storagePath: "images/quotes",
            acceptedFiles: ["image/*"],
            metadata: {
                cacheControl: "max-age=1000000",
            },
        },
    },
    quoteImageFirebaseSignedUrl: {
        name: "Quote Image (signed)",
        dataType: "string",
        readOnly: true,
        disabled: {
            hidden: true,
        },
    },
    quoteLine1: {
        name: "Quote Line 1",
        dataType: "string",
    },
    quoteLine2: {
        name: "Quote Line 2",
        dataType: "string",
    },
    carouselType: {
        name: "Carousel Type",
        dataType: "string",
        enumValues: {
            countries: "countries",
            destinations: "destinations",
            trips: "trips",
        },
    },
} as PropertiesOrBuilders<NomadCloudAppGeneralSettings>;

const appGeneralSettingsLocaleCollection = buildCollection<NomadCloudAppGeneralSettingsLocale>({
    path: "translation",
    customId: getLocales(),
    name: "Translation",
    singularName: "Translation",
    properties: {
        quoteLine1: appGeneralSettingsProperties.quoteLine1,
        quoteLine2: appGeneralSettingsProperties.quoteLine2,
    },
});

export const appGeneralSettingsCollection = buildCollection({
    path: `${collectionVersionedPrefix()}/operatorSettings`,
    name: "App General Settings",
    properties: appGeneralSettingsProperties,
    subcollections: [appGeneralSettingsLocaleCollection],
});
