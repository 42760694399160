import { PropertiesOrBuilders, buildCollection } from "firecms";
import { NomadCloudAppOnboardingSettings, NomadCloudAppOnboardingSettingsLocale } from "./onboarding.settings.types";
import { getLocales } from "../../locale_config";
import { collectionVersionedPrefix } from "../data_version";

export const appOnboardingSettingsProperties = {
    stepOneImageFirebaseUrl: {
        name: "Step 1 Image",
        description: "Step 1 Image",
        dataType: "string",
        storage: {
            storagePath: "images/onboardings",
            acceptedFiles: ["image/*"],
            metadata: {
                cacheControl: "max-age=1000000",
            },
        },
    },
    stepOneImageFirebaseSignedUrl: {
        name: "Step 1 Image (signed)",
        dataType: "string",
        readOnly: true,
        disabled: {
            hidden: true,
        },
    },
    stepOneTitle: {
        name: "Step 1 Title",
        description: "Step 1 Title (Max 15 chars)",
        validation: { required: true, max: 15 },
        dataType: "string",
    },
    stepOneMessage: {
        name: "Step 1 Message",
        description: "Step 1 Message (Max 75 chars)",
        validation: { required: true, max: 75 },
        dataType: "string",
    },

    stepTwoImageFirebaseUrl: {
        name: "Step 2 Image",
        description: "Step 2 Image",
        dataType: "string",
        storage: {
            storagePath: "images/onboardings",
            acceptedFiles: ["image/*"],
            metadata: {
                cacheControl: "max-age=1000000",
            },
        },
    },
    stepTwoImageFirebaseSignedUrl: {
        name: "Step 2 Image (signed)",
        dataType: "string",
        readOnly: true,
        disabled: {
            hidden: true,
        },
    },

    stepTwoTitle: {
        name: "Step 2 Title",
        description: "Step 2 Title (Max 15 chars)",
        validation: { required: true, max: 15 },
        dataType: "string",
    },
    stepTwoMessage: {
        name: "Step 2 Message",
        description: "Step 2 Message (Max 75 chars)",
        validation: { required: true, max: 75 },
        dataType: "string",
    },

    stepThreeImageFirebaseUrl: {
        name: "Step 3 Image",
        description: "Step 3 Image",
        dataType: "string",
        storage: {
            storagePath: "images/onboardings",
            acceptedFiles: ["image/*"],
            metadata: {
                cacheControl: "max-age=1000000",
            },
        },
    },
    stepThreeImageFirebaseSignedUrl: {
        name: "Step 3 Image (signed)",
        dataType: "string",
        readOnly: true,
        disabled: {
            hidden: true,
        },
    },
    stepThreeTitle: {
        name: "Step 3 Title",
        description: "Step 3 Title (Max 15 chars)",
        validation: { required: true, max: 15 },
        dataType: "string",
    },
    stepThreeMessage: {
        name: "Step 3 Message",
        description: "Step 3 Message (Max 75 chars)",
        validation: { required: true, max: 75 },
        dataType: "string",
    },
    afterOnboardingSecondTitle: {
        name: "After onboarding Second title",
        description: "After onboarding Second title (first one is Operator Name)",
        validation: { required: true, max: 30 },
        dataType: "string",
    },
    afterOnboardingText: {
        name: "After onboarding Text",
        description: "After onboarding Text (Max 100 chars)",
        validation: { required: true, max: 100 },
        dataType: "string",
    },

    afterOnboardingImageFirebaseUrl: {
        name: "After onboarding banner image",
        description: "After onboarding banner image",
        dataType: "string",
        storage: {
            storagePath: "images/onboardings",
            acceptedFiles: ["image/*"],
            metadata: {
                cacheControl: "max-age=1000000",
            },
        },
    },
    afterOnboardingImageFirebaseSignedUrl: {
        name: "After onboarding banner image (signed)",
        dataType: "string",
        readOnly: true,
        disabled: {
            hidden: true,
        },
    },
    afterOnboardingVideo: {
        name: "Show embedded video",
        dataType: "boolean",
    },
} as PropertiesOrBuilders<NomadCloudAppOnboardingSettings>;

const appOnboardingSettingsLocaleCollection = buildCollection<NomadCloudAppOnboardingSettingsLocale>({
    path: "translation",
    customId: getLocales(),
    name: "Translation",
    singularName: "Translation",
    properties: {
        stepOneTitle: appOnboardingSettingsProperties.stepOneTitle,
        stepOneMessage: appOnboardingSettingsProperties.stepOneMessage,
        stepTwoTitle: appOnboardingSettingsProperties.stepTwoTitle,
        stepTwoMessage: appOnboardingSettingsProperties.stepTwoMessage,
        stepThreeTitle: appOnboardingSettingsProperties.stepThreeTitle,
        stepThreeMessage: appOnboardingSettingsProperties.stepThreeMessage,

        afterOnboardingSecondTitle: appOnboardingSettingsProperties.afterOnboardingSecondTitle,
        afterOnboardingText: appOnboardingSettingsProperties.afterOnboardingText,
    },
});

export const appOnboardingSettingsCollection = buildCollection({
    path: `${collectionVersionedPrefix()}/operatorSettings`,
    name: "App Onboarding Settings",
    properties: appOnboardingSettingsProperties,
    subcollections: [appOnboardingSettingsLocaleCollection],
});
