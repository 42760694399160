import { PropertiesOrBuilders, buildCollection } from "firecms";
import { getLocales } from "../../locale_config";
import { collectionVersionedPrefix } from "../data_version";
import {
    NomadCloudAppIntegrationsSettings,
    NomadCloudAppIntegrationsSettingsLocale,
} from "./integrations.settings.types";

export const appIntegrationsSettingsProperties = {
    wordpressUrl: {
        name: "Wordpress URL",
        description: "Wordpress URL (ends with /wp-json)",
        dataType: "string",
    },
} as PropertiesOrBuilders<NomadCloudAppIntegrationsSettings>;

const appIntegrationsSettingsLocaleCollection = buildCollection<NomadCloudAppIntegrationsSettingsLocale>({
    path: "translation",
    customId: getLocales(),
    name: "Translation",
    singularName: "Translation",
    properties: {
        wordpressUrl: appIntegrationsSettingsProperties.wordpressUrl,
    },
});

export const appIntegrationsSettingsCollection = buildCollection({
    path: `${collectionVersionedPrefix()}/operatorSettings`,
    name: "App Integrations Settings",
    properties: appIntegrationsSettingsProperties,
    subcollections: [appIntegrationsSettingsLocaleCollection],
});
