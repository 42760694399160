import { NomadCloudVoucherType, NomadCloudVoucherTypeLocale } from "../types";
import { EntityReference, PropertiesOrBuilders, buildCollection, buildEntityCallbacks } from "firecms";
import { collectionVersionedPrefix } from "../data_version";
import { getLocales } from "../../locale_config";
import { itineraryCollection } from "./itinerary_collection";

const voucherTypeProperties = {
    active: {
        name: "Active",
        dataType: "boolean",
        description: "Is the Voucher Type Active?",
    },
    featured: {
        name: "Featured ⭐",
        description: "Is the Voucher Type Featured / Shown on the landing page?",
        dataType: "boolean",
    },
    title: {
        name: "Title",
        description: "Voucher Type Title (Max 80 chars)",
        validation: {
            required: true,
            max: 120,
        },
        dataType: "string",
    },
    description: {
        name: "Description",
        dataType: "string",
        description: "Voucher Type Description (Max 1000 chars)",
        validation: {
            required: true,
            max: 1000,
        },
        multiline: true,
        markdown: true,
        columnWidth: 300,
    },
    partnership: {
        dataType: "reference",
        name: "Partnership",
        description: "Partnership",
        path: `${collectionVersionedPrefix()}/partnerships`,
        previewProperties: ["name"],
    },
    voucherTypeImageFirebaseUrl: {
        name: "Voucher Type Image",
        description: "Voucher Type shown in the App",
        validation: {
            required: true,
        },
        dataType: "string",
        storage: {
            storagePath: "images/voucherTypeTypes",
            acceptedFiles: ["image/*"],
        },
    },
    voucherTypeImageFirebaseSignedUrl: {
        name: "Voucher Type Image (signed)",
        dataType: "string",
        readOnly: true,
        disabled: {
            hidden: true,
        },
    },
    voucherTypeConfiguration: {
        description: "Voucher Type Configuration (figure out)",
        name: "Voucher Type Configuration (figure out)",
        dataType: "string",
    },
    modifiedAt: {
        name: "Modified At",
        dataType: "date",
        autoValue: "on_update",
        readOnly: true,
    },
    modifiedBy: {
        dataType: "reference",
        name: "Modified By",
        path: `${collectionVersionedPrefix()}/users`,
        previewProperties: ["displayName"],
        readOnly: true,
    },
    createdAt: {
        name: "Created At",
        dataType: "date",
        autoValue: "on_create",
        readOnly: true,
    },
    createdBy: {
        dataType: "reference",
        name: "Created By",
        path: `${collectionVersionedPrefix()}/users`,
        previewProperties: ["displayName"],
        readOnly: true,
    },
} as PropertiesOrBuilders<NomadCloudVoucherType>;

export const voucherTypeCollection = () => {
    return buildCollection<NomadCloudVoucherType>({
        path: `${collectionVersionedPrefix()}/voucherTypes`,
        name: "Voucher Types",
        singularName: "Voucher Type",
        group: "Ecommerce",
        description: "Voucher Types",
        textSearchEnabled: false,
        icon: "ConfirmationNumber",
        subcollections: [voucherTypeLocaleCollection, itineraryCollection()],
        properties: voucherTypeProperties,
        callbacks: buildEntityCallbacks({
            onPreSave: async ({ values, entityId, context }) => {
                if (!entityId) {
                    // creation
                    values.createdBy = new EntityReference(
                        context.authController.user!.uid,
                        `${collectionVersionedPrefix()}/users`
                    );
                }

                values.modifiedBy = new EntityReference(
                    context.authController.user!.uid,
                    `${collectionVersionedPrefix()}/users`
                );
                return values;
            },
        }),
    });
};

export const voucherTypeLocaleCollection = buildCollection<NomadCloudVoucherTypeLocale>({
    path: "translation",
    customId: getLocales(),
    name: "Translation",
    singularName: "Translation",
    properties: {
        title: voucherTypeProperties.title,
        description: voucherTypeProperties.description,

        modifiedAt: voucherTypeProperties.modifiedAt,
        modifiedBy: voucherTypeProperties.modifiedBy,
        createdAt: voucherTypeProperties.createdAt,
        createdBy: voucherTypeProperties.createdBy,
    },
});
