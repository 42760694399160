import { FirebaseLoginView, FirebaseLoginViewProps } from "firecms";

export function ContentManagementLoginView(props: FirebaseLoginViewProps) {

    return (

        <FirebaseLoginView
            {...props}
            disableSignupScreen={false}
            noUserComponent={<>
                No User!
            </>}
        />
    );
}