import { EntityReference, PropertiesOrBuilders, buildCollection, buildEntityCallbacks } from "firecms";
import { getLocales } from "../../locale_config";
import { collectionVersionedPrefix } from "../data_version";
import { NomadCloudTripCategory, NomadCloudTripCategoryLocale } from "../types";

const tripCategoryProperties = {
    active: {
        name: "Active",
        description: "Is the Category Active?",
        dataType: "boolean",
    },
    name: {
        name: "Name",
        dataType: "string",
        description: "Category name. (Max 20 chars)",
        validation: { required: true, max: 20 },
    },
    modifiedAt: {
        name: "Modified At",
        dataType: "date",
        autoValue: "on_update",
        readOnly: true,
    },
    modifiedBy: {
        dataType: "reference",
        name: "Modified By",
        path: `${collectionVersionedPrefix()}/users`,
        previewProperties: ["displayName"],
        readOnly: true,
    },
    createdAt: {
        name: "Created At",
        dataType: "date",
        autoValue: "on_create",
        readOnly: true,
    },
    createdBy: {
        dataType: "reference",
        name: "Created By",
        path: `${collectionVersionedPrefix()}/users`,
        previewProperties: ["displayName"],
        readOnly: true,
    },
} as PropertiesOrBuilders<NomadCloudTripCategory>;

export const tripCategoryCollection = () =>
    {
        return buildCollection<NomadCloudTripCategory>({
            path: `${collectionVersionedPrefix()}/tripCategories`,
            name: "Trip Categories",
            singularName: "Trip Category",
            group: "Lists",
            description: "Trip Categories",
            textSearchEnabled: false,
            icon: "Category",
            subcollections: [tripCategoryLocaleCollection],
            properties: tripCategoryProperties,
            callbacks: buildEntityCallbacks({
                onPreSave: async ({ values, entityId, context }) => {
                    if (!entityId) {
                        // creation
                        values.createdBy = new EntityReference(
                            context.authController.user!.uid,
                            `${collectionVersionedPrefix()}/users`
                        );
                    }

                    values.modifiedBy = new EntityReference(
                        context.authController.user!.uid,
                        `${collectionVersionedPrefix()}/users`
                    );
                    return values;
                },
            }),
        });
    }

export const tripCategoryLocaleCollection = buildCollection<NomadCloudTripCategoryLocale>({
    path: "translation",
    customId: getLocales(),
    name: "Translation",
    singularName: "Translation",
    properties: {
        name: tripCategoryProperties.name,

        modifiedAt: tripCategoryProperties.modifiedAt,
        modifiedBy: tripCategoryProperties.modifiedBy,
        createdAt: tripCategoryProperties.createdAt,
        createdBy: tripCategoryProperties.createdBy,
    }
});