import { buildCollection } from "firecms";
import { collectionVersionedPrefix } from "../data_version";
import { NomadCloudAdminBooking } from "../types";

export const bookingsCollection = buildCollection<NomadCloudAdminBooking>({
    path: `${collectionVersionedPrefix()}/bookings`,
    name: "Bookings",
    singularName: "Booking",
    group: "Operations",
    description: "Bookings",
    textSearchEnabled: false,
    icon: "Event",
    properties: {
        createdAt: {
            name: "Created At",
            dataType: "date",
            autoValue: "on_create",
            readOnly: true,
        },
        user: {
            dataType: "reference",
            name: "User",
            description: "User",
            path: `${collectionVersionedPrefix()}/users`,
            previewProperties: ["displayName"],
            readOnly: true,
        },
        organizerNote: {
            name: "Organizer Note",
            dataType: "string"
        },
        originalPrice: {
            name: "Original Price",
            dataType: "number",
            readOnly: true,
        },
        originalRequest: {
            name: "Booking Data",
            dataType: "map",
            properties: {
                tripId: {
                    dataType: "string",
                    name: "Trip Id",
                    disabled: {
                        hidden: true,
                    },
                },
                itineraryId: {
                    dataType: "string",
                    name: "Itinerary Id",
                    disabled: {
                        hidden: true,
                    },
                },
                trip: {
                    dataType: "reference",
                    name: "Trip",
                    description: "Trip",
                    path: `${collectionVersionedPrefix()}/trips`,
                    previewProperties: ["title"],
                    readOnly: true,
                },
                itineraryName: {
                    dataType: "string",
                    name: "Itinerary Name"
                },
                startDate: {
                    dataType: "string",
                    name: "Start Date"
                },
                endDate: {
                    dataType: "string",
                    name: "End Date"
                },
                note: {
                    dataType: "string",
                    name: "Note"
                },
                passengers: {
                    name: "Passengers",
                    dataType: "map",
                    properties: {
                        regular: {
                            name: "Regular",
                            description: "Regular",
                            dataType: "number"
                        },
                        kid: {
                            name: "Kid",
                            description: "Kid",
                            dataType: "number"
                        },
                        senior: {
                            name: "Senior",
                            description: "Senior",
                            dataType: "number"
                        }
                    }
                },
                products: {
                    dataType: "array",
                    name: "Products",
                    description: "Products",
                    of: {
                        dataType: "map",
                        properties: {
                            product: {
                                name: "Product",
                                description: "Product",
                                dataType: "reference",
                                path: `${collectionVersionedPrefix()}/products`,
                                previewProperties: ["title"],
                            },
                            count: {
                                name: "Count",
                                description: "Count",
                                dataType: "number",
                                validation: {
                                    min: 0,
                                    max: 50
                                }
                            },
                        },
                        expanded: true,
                    },
                },
            },
            readOnly: true,
        },
    },
});
