/* eslint-disable no-tabs */
import { FieldDescription, FieldProps } from "firecms";
import DatePicker, { Calendar, DateObject } from "react-multi-date-picker";
import colors from "react-multi-date-picker/plugins/colors";

export default function CustomMultiDatePicker({
                                                 property,
                                                 value,
                                                 setValue,
                                                 setFieldValue, // use this function to update a different field
                                                 customProps,
                                                 touched,
                                                 error,
                                                 isSubmitting,
                                                 context, // the rest of the entity values here
                                                 ...props
                                             }: FieldProps<string[], any>) {

    return (
        <>
            <Calendar
                multiple={true}
                fullYear={false}
                value={value ?? []}
                format="YYYY-MM-DD"
                onChange={(newValues) =>
                    {
                        if (newValues == null) {
                            return setValue([]);
                        }

                        if (newValues instanceof DateObject) {
                            return setValue([newValues.format()]);
                        }

                        return setValue(newValues.map(dateObj => dateObj.format()));
                    }
                }
            />

            <FieldDescription property={property}/>
        </>

    );

}