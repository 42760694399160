import { EnumValues } from "firecms";
import { LanguageCode, findLanguage } from "locale-util";

export const getLocales = (): EnumValues => {
    const locales = {} as { [x: string]: string };
    const langCodeArray = import.meta.env.VITE_OPERATOR_LOCALES
        ? (import.meta.env.VITE_OPERATOR_LOCALES as string).split("|")
        : [];

    for (const langCode of langCodeArray) {
        const language = findLanguage(langCode as LanguageCode);
        if (language) {
            locales[langCode] = language?.englishName;
        } else {
            // console.log(`Unknown language code: ${langCode} (skipping)`);
        }
    }
    return locales;
};
