import { PropertiesOrBuilders, buildCollection } from "firecms";
import { getLocales } from "../../locale_config";
import { collectionVersionedPrefix } from "../data_version";
import { NomadCloudAppAboutUsSettings, NomadCloudAppAboutUsSettingsLocale } from "./aboutus.settings.types";

export const appAboutUsSettingsProperties = {
    aboutUsImageFirebaseUrl: {
        name: "About Us Image",
        description: "About Us Image",
        dataType: "string",
        storage: {
            storagePath: "images/aboutus",
            acceptedFiles: ["image/*"],
            metadata: {
                cacheControl: "max-age=1000000",
            },
        },
    },
    aboutUsImageFirebaseSignedUrl: {
        name: "About Us Image (signed)",
        dataType: "string",
        readOnly: true,
        disabled: {
            hidden: true,
        },
    },
    title: {
        name: "Title",
        description: "Title (Max 40 chars)",
        validation: { required: true, max: 40 },
        dataType: "string",
    },
    textMarkdown: {
        name: "Text (Markdown)",
        description: "Text (Markdown)",
        validation: { required: true },
        markdown: true,
        dataType: "string",
    },

    webLink: {
        name: "Web Link",
        description: "Web Link",
        dataType: "string",
    },
    facebookLink: {
        name: "Facebook URL",
        description: "Facebook URL",
        dataType: "string",
    },
    facebookPageId: {
        name: "Facebook Page Id",
        description: "Facebook Page Id",
        dataType: "string",
    },
    instagramAccountId: {
        name: "Instagram Account Id",
        description: "Instagram Account Id",
        dataType: "string",
    },
    phoneNumber: {
        name: "Phone Number",
        description: "Phone Number",
        dataType: "string",
    },
    whatsapp: {
        name: "Whatsapp",
        description: "Whatsapp",
        dataType: "string",
    },
    email: {
        name: "E-mail",
        description: "E-mail",
        dataType: "string",
    },
} as PropertiesOrBuilders<NomadCloudAppAboutUsSettings>;

const appAboutUsSettingsLocaleCollection = buildCollection<NomadCloudAppAboutUsSettingsLocale>({
    path: "translation",
    customId: getLocales(),
    name: "Translation",
    singularName: "Translation",
    properties: {
        title: appAboutUsSettingsProperties.title,
        textMarkdown: appAboutUsSettingsProperties.textMarkdown,
    },
});

export const appAboutUsSettingsCollection = buildCollection({
    path: `${collectionVersionedPrefix()}/operatorSettings`,
    name: "App About Us Settings",
    properties: appAboutUsSettingsProperties,
    subcollections: [appAboutUsSettingsLocaleCollection],
});
