import { EntityReference, PropertiesOrBuilders, buildCollection, buildEntityCallbacks } from "firecms";
import { getLocales } from "../../locale_config";
import { collectionVersionedPrefix } from "../data_version";
import { NomadCloudDestination, NomadCloudDestinationLocale } from "../types";

const destinationProperties = {
    active: {
        name: "Active",
        dataType: "boolean",
        description: "Is the Destination Active?"
    },
    featured: {
        name: "Featured ⭐",
        dataType: "boolean",
        description: "Is the Country Featured / Shown on the landing page?"
    },
    name: {
        name: "Name",
        description: "Name of the Destination (Max 30 chars)",
        validation: { required: true, max: 30 },
        dataType: "string"
    },
    description: {
        name: "Description",
        description: "Destination description, shown in the App (Max 250 chars)",
        validation: { required: true, max: 250 },
        dataType: "string",
        multiline: true,
        markdown: true
    },
    coverImageFirebaseUrl: {
        name: "Cover Image",
        description: "Cover Image",
        dataType: "string",
        storage: {
            storagePath: "images/destinations",
            acceptedFiles: ["image/*"],
            metadata: {
                cacheControl: "max-age=1000000"
            }
        }
    },
    coverImageFirebaseSignedUrl: {
        name: "Cover Image (signed)",
        dataType: "string",
        readOnly: true,
        disabled: {
            hidden: true
        }
    },
    country: {
        dataType: "reference",
        validation: { required: true },
        description: "Which Country does this Destination belong to?",
        name: "Country",
        path: `${collectionVersionedPrefix()}/countries`,
        previewProperties: ["name"]
    },
    tripCount: {
        description: "Number of Trips in this Destination (auto-calculated)",
        name: "Trip Count",
        dataType: "number",
        readOnly: true
    },
    modifiedAt: {
        name: "Modified At",
        dataType: "date",
        autoValue: "on_update",
        readOnly: true
    },
    modifiedBy: {
        dataType: "reference",
        name: "Modified By",
        path: `${collectionVersionedPrefix()}/users`,
        previewProperties: ["displayName"],
        readOnly: true
    },
    createdAt: {
        name: "Created At",
        dataType: "date",
        autoValue: "on_create",
        readOnly: true
    },
    createdBy: {
        dataType: "reference",
        name: "Created By",
        path: `${collectionVersionedPrefix()}/users`,
        previewProperties: ["displayName"],
        readOnly: true
    }
} as PropertiesOrBuilders<NomadCloudDestination>;

export const destinationCollection = () => {
    return buildCollection<NomadCloudDestination>({
        path: `${collectionVersionedPrefix()}/destinations`,
        name: "Destinations",
        singularName: "Destination",
        group: "Main",
        description: "Destinations",
        textSearchEnabled: false,
        icon: "LocationCity",
        subcollections: [destinationLocaleCollection],
        properties: destinationProperties,
        callbacks: buildEntityCallbacks({
            onPreSave: async ({ values, entityId, context }) => {
                if (!entityId) {
                    // creation
                    values.createdBy = new EntityReference(
                        context.authController.user!.uid,
                        `${collectionVersionedPrefix()}/users`
                    );
                }

                values.modifiedBy = new EntityReference(
                    context.authController.user!.uid,
                    `${collectionVersionedPrefix()}/users`
                );
                return values;
            }
        })
    });
}

export const destinationLocaleCollection = buildCollection<NomadCloudDestinationLocale>({
    path: "translation",
    customId: getLocales(),
    name: "Translation",
    singularName: "Translation",
    properties: {
        name: destinationProperties.name,
        description: destinationProperties.description,

        modifiedAt: destinationProperties.modifiedAt,
        modifiedBy: destinationProperties.modifiedBy,
        createdAt: destinationProperties.createdAt,
        createdBy: destinationProperties.createdBy
    }
});
