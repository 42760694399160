import { EntityReference, PropertiesOrBuilders, buildCollection, buildEntityCallbacks, buildProperty } from "firecms";
import { getLocales } from "../../locale_config";
import { collectionVersionedPrefix } from "../data_version";
import { NomadCloudAccommodation, NomadCloudAccommodationLocale } from "../types";

const accommodationProperties = {
    active: {
        name: "Active",
        description: "Is the Accommodation Active?",
        dataType: "boolean",
    },
    featured: {
        name: "Featured ⭐",
        description: "Featured Accommodations are displayed first",
        dataType: "boolean",
    },
    name: {
        name: "Name",
        description: "Accommodation name (Max 30 chars)",
        validation: { required: true, max: 30 },
        dataType: "string",
    },
    description: {
        name: "Description",
        description: "Accommodation description (Max 100 chars)",
        validation: { required: true, max: 100 },
        dataType: "string",
        multiline: true,
        markdown: true,
    },
    galleryFirebaseUrlArray: {
        dataType: "array",
        name: "Gallery",
        description: "Accommodation gallery",
        validation: {
            required: true,
        },
        of: buildProperty({
            name: "Image",
            dataType: "string",
            storage: {
                mediaType: "image",
                storagePath: "images/trip_accommodation_galleries",
                acceptedFiles: ["image/*"],
            },
        }),
    },
    galleryFirebaseSignedUrlArray: {
        name: "Gallery (signed)",
        dataType: "array",
        of: {
            dataType: "string",
        },
        disabled: {
            hidden: true,
        },
    },
    features: {
        name: "Features",
        description: "Accommodation Features (Follow Guidelines)",
        dataType: "array",
        of: {
            dataType: "reference",
            path: `${collectionVersionedPrefix()}/accommodationFeatures`,
            previewProperties: ["name"],
        },
    },
    modifiedAt: {
        name: "Modified At",
        dataType: "date",
        autoValue: "on_update",
        readOnly: true,
    },
    modifiedBy: {
        dataType: "reference",
        name: "Modified By",
        path: `${collectionVersionedPrefix()}/users`,
        previewProperties: ["displayName"],
        readOnly: true,
    },
    createdAt: {
        name: "Created At",
        dataType: "date",
        autoValue: "on_create",
        readOnly: true,
    },
    createdBy: {
        dataType: "reference",
        name: "Created By",
        path: `${collectionVersionedPrefix()}/users`,
        previewProperties: ["displayName"],
        readOnly: true,
    },
} as PropertiesOrBuilders<NomadCloudAccommodation>;

export const accommodationCollection = () => {
    return buildCollection<NomadCloudAccommodation>({
        path: `${collectionVersionedPrefix()}/accommodation`,
        name: "Accommodation",
        singularName: "Accommodation",
        group: "Main",
        description: "Accommodation",
        textSearchEnabled: false,
        icon: "Hotel",
        properties: accommodationProperties,
        callbacks: buildEntityCallbacks({
            onPreSave: async ({ values, entityId, context }) => {
                if (!entityId) {
                    // creation
                    values.createdBy = new EntityReference(
                        context.authController.user!.uid,
                        `${collectionVersionedPrefix()}/users`
                    );
                }

                values.modifiedBy = new EntityReference(
                    context.authController.user!.uid,
                    `${collectionVersionedPrefix()}/users`
                );
                return values;
            },
        }),
    });
}

export const accommodationLocaleCollection = buildCollection<NomadCloudAccommodationLocale>({
    path: "translation",
    customId: getLocales(),
    name: "Translation",
    singularName: "Translation",
    properties: {
        name: accommodationProperties.name,
        description: accommodationProperties.description,

        modifiedAt: accommodationProperties.modifiedAt,
        modifiedBy: accommodationProperties.modifiedBy,
        createdAt: accommodationProperties.createdAt,
        createdBy: accommodationProperties.createdBy,
    }
});

