import { Link } from "@mui/icons-material";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Container,
    Grid,
    IconButton,
    Tooltip,
    Typography
} from "@mui/material";

import {
    useAuthController,
    useSelectionController,
    useSideEntityController
} from "firecms";
import { collectionVersionedPrefix } from "../data_version";
import { appOnboardingSettingsCollection } from "./onboarding.settings";
import { appGeneralSettingsCollection } from "./general.settings";
import { appColorSettingsCollection } from "./color.settings";
import { appIntegrationsSettingsCollection } from "./integrations.settings";
import { appAboutUsSettingsCollection } from "./aboutus.settings";

export function SettingsView() {

    const selectionController = useSelectionController();

    console.log("Selection from ExampleCMSView", selectionController.selectedEntities);

    // hook to open the side dialog that shows the entity forms
    const sideEntityController = useSideEntityController();

    // hook to do operations related to authentication
    const authController = useAuthController();

    
    // const wpSettingsCollection = buildCollection({
    //     path: "xxxx",
    //     name: "Wordpress Settings",
    //     properties: {
    //         endpointUrl: {
    //             name: "Endpoint URL",
    //             dataType: "string"
    //         }
    //     }
    // });

    const randomLink = (
        <Tooltip
            title="Random link title">
            <IconButton
                href={"https://lalalalal"}
                rel="noopener noreferrer"
                target="_blank"
                component={"a"}
                size="large">
                <Link/>
            </IconButton>
        </Tooltip>
    );

    return (
        <Box
            display="flex"
            width={"100%"}
            height={"100%"}>

            <Box m="auto"
                 display="flex"
                 flexDirection={"column"}
                 alignItems={"center"}
                 justifyItems={"center"}>

                <Container maxWidth={"md"}
                           sx={{
                               my: 4
                           }}>

                    <Grid container rowSpacing={5} columnSpacing={2}>

                        <Grid item xs={12}>
                            <Typography variant={"h4"}>
                                Operator settings
                            </Typography>
                            <Typography>
                                {authController.user
                                    ? <>Logged in
                                        as {authController.user.displayName}</>
                                    : <>You are not logged in</>}
                            </Typography>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <Card variant="outlined" sx={{ height: "100%" }}>
                                <CardContent>
                                    <Typography>
                                        Configure app-wide settings.
                                    </Typography>
                                </CardContent>

                                <CardActions>
                                    <Button
                                        onClick={() => sideEntityController.open({
                                            entityId: "app_general_settings",
                                            path: `${collectionVersionedPrefix()}/operatorSettings`, // this path is not mapped in our collections
                                            collection: appGeneralSettingsCollection,
                                            width: 800
                                        })}
                                        color="primary">
                                        App General Settings
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <Card variant="outlined" sx={{ height: "100%" }}>
                                <CardContent>
                                    <Typography>
                                        Configure color settings.
                                    </Typography>
                                </CardContent>

                                <CardActions>
                                    <Button
                                        onClick={() => sideEntityController.open({
                                            entityId: "app_color_settings",
                                            path: `${collectionVersionedPrefix()}/operatorSettings`, // this path is not mapped in our collections
                                            collection: appColorSettingsCollection("App Color Settings (Light Theme)"),
                                            width: 800
                                        })}
                                        color="primary">
                                        App Color Settings
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <Card variant="outlined" sx={{ height: "100%" }}>
                                <CardContent>
                                    <Typography>
                                        Configure color settings.
                                    </Typography>
                                </CardContent>

                                <CardActions>
                                    <Button
                                        onClick={() => sideEntityController.open({
                                            entityId: "app_color_settings_dark",
                                            path: `${collectionVersionedPrefix()}/operatorSettings`, // this path is not mapped in our collections
                                            collection: appColorSettingsCollection("App Color Settings (Dark Theme)"),
                                            width: 800
                                        })}
                                        color="primary">
                                        App Color Settings (Dark Theme)
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <Card variant="outlined" sx={{ height: "100%" }}>
                                <CardContent>
                                    <Typography>
                                        Configure images and the text that is displayed when the user opens the app for the first time.
                                    </Typography>
                                </CardContent>

                                <CardActions>
                                    <Button
                                        onClick={() => sideEntityController.open({
                                            entityId: "app_onboarding_settings",
                                            path: `${collectionVersionedPrefix()}/operatorSettings`, // this path is not mapped in our collections
                                            collection: appOnboardingSettingsCollection,
                                            width: 800
                                        })}
                                        color="primary">
                                        App Onboarding Settings
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <Card variant="outlined" sx={{ height: "100%" }}>
                                <CardContent>
                                    <Typography>
                                        Configure &apos;About Us&apos; page.
                                    </Typography>
                                </CardContent>

                                <CardActions>
                                    <Button
                                        onClick={() => sideEntityController.open({
                                            entityId: "app_aboutus_settings",
                                            path: `${collectionVersionedPrefix()}/operatorSettings`, // this path is not mapped in our collections
                                            collection: appAboutUsSettingsCollection,
                                            width: 800
                                        })}
                                        color="primary">
                                        App &apos;About Us&apos; Settings
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <Card variant="outlined" sx={{ height: "100%" }}>
                                <CardContent>
                                    <Typography>
                                        Configure Integrations.
                                    </Typography>
                                </CardContent>

                                <CardActions>
                                    <Button
                                        onClick={() => sideEntityController.open({
                                            entityId: "app_integrations_settings",
                                            path: `${collectionVersionedPrefix()}/operatorSettings`, // this path is not mapped in our collections
                                            collection: appIntegrationsSettingsCollection,
                                            width: 800
                                        })}
                                        color="primary">
                                        App Integration Settings
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>

                        <Grid item xs={12}>
                            {randomLink}
                        </Grid>

                    </Grid>

                </Container>
            </Box>
        </Box>
    );
}
