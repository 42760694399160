import { EntityReference, PropertiesOrBuilders, buildCollection, buildEntityCallbacks } from "firecms";
import { getLocales } from "../../locale_config";
import { collectionVersionedPrefix } from "../data_version";
import { NomadCloudGuide, NomadCloudGuideLocale } from "../types";

const guideProperties = {
    active: {
        name: "Active",
        dataType: "boolean",
        description: "Is the Guide Active?",
    },
    displayName: {
        name: "Name",
        description: "Full Name of the person (Max 25 chars)",
        validation: { required: true, max: 25 },
        dataType: "string",
    },
    phone: {
        name: "Phone",
        description: "Phone",
        validation: { max: 25 },
        dataType: "string",
    },
    whatsapp: {
        name: "Whatsapp",
        description: "Whatsapp",
        validation: { max: 25 },
        dataType: "string",
    },
    email: {
        name: "Email",
        description: "Email",
        validation: { max: 50 },
        dataType: "string",
    },
    bio: {
        name: "Biography",
        dataType: "string",
        description: "Biography (Max 250 chars)",
        validation: { required: true, max: 250 },
        multiline: true,
    },
    photoFirebaseUrl: {
        name: "Photo",
        description: "An up-to-date photo of the Guide",
        dataType: "string",
        validation: { required: true },
        storage: {
            storagePath: "images/guides",
            acceptedFiles: ["image/*"],
        },
    },
    photoFirebaseSignedUrl: {
        name: "Photo Url (signed)",
        dataType: "string",
        readOnly: true,
        disabled: {
            hidden: true,
        },
    },
    user: {
        dataType: "reference",
        name: "User",
        description: "Reference to User in the system (leave as is)",
        path: `${collectionVersionedPrefix()}/users`,
        previewProperties: ["displayName"],
    },
    modifiedAt: {
        name: "Modified At",
        dataType: "date",
        autoValue: "on_update",
        disabled: true,
        readOnly: true,
    },
    modifiedBy: {
        dataType: "reference",
        name: "Modified By",
        path: `${collectionVersionedPrefix()}/users`,
        previewProperties: ["displayName"],
        disabled: true,
        readOnly: true,
    },
    createdAt: {
        name: "Created At",
        dataType: "date",
        autoValue: "on_create",
        disabled: true,
        readOnly: true,
    },
    createdBy: {
        dataType: "reference",
        name: "Created By",
        path: `${collectionVersionedPrefix()}/users`,
        previewProperties: ["displayName"],
        disabled: true,
        readOnly: true,
    },
} as PropertiesOrBuilders<NomadCloudGuide>;

export const guideCollection = () => {
    return buildCollection<NomadCloudGuide>({
        path: `${collectionVersionedPrefix()}/guides`,
        name: "Guides",
        singularName: "Guide",
        group: "HR",
        description: "Guides",
        textSearchEnabled: false,
        icon: "AssignmentInd",
        subcollections: [guideLocaleCollection],
        properties: guideProperties,
        callbacks: buildEntityCallbacks({
            onPreSave: async ({ values, entityId, context }) => {
                if (!entityId) {
                    // creation
                    values.createdBy = new EntityReference(
                        context.authController.user!.uid,
                        `${collectionVersionedPrefix()}/users`
                    );
                }

                values.modifiedBy = new EntityReference(
                    context.authController.user!.uid,
                    `${collectionVersionedPrefix()}/users`
                );
                return values;
            },
        }),
    });
};

export const guideLocaleCollection = buildCollection<NomadCloudGuideLocale>({
    path: "translation",
    customId: getLocales(),
    name: "Translation",
    singularName: "Translation",
    properties: {
        bio: guideProperties.bio,

        modifiedAt: guideProperties.modifiedAt,
        modifiedBy: guideProperties.modifiedBy,
        createdAt: guideProperties.createdAt,
        createdBy: guideProperties.createdBy,
    }
});
